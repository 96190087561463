import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { Sidebar } from './sidebar/Sidebar'
import { ScrollToTop } from '../components/shared/ScrollToTop'
import { Modal } from '../components/shared/modal/Modal'

interface Props {
  children: JSX.Element
}

export const Dashboard = ({ children }: Props) => {
  return (
    <>
      <LayoutContainer>
        <Sidebar />
        <Content className={'no-scrollbar'}>
          {children}
          <ScrollToTop />
        </Content>
      </LayoutContainer>
      <Modal />
    </>
  )
}

interface LayoutContainerProps {
  loading?: boolean
}

export const LayoutContainer = styled.div<LayoutContainerProps>`
  ${tw`bg-gradient-to-tl from-gray-800 to-gray-700`}
  ${({ loading }) => (loading ? tw`h-screen` : tw`flex justify-center w-full text-gray-100 h-fit`)}
`

const Content = styled.div`
  ${tw`min-h-screen transition transition-all ease-in-out px-3 w-full max-w-[1700px]`}
`
