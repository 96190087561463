import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { Vendor } from '../../../utils/vendors'
import { UserEditTab } from '../../admin/users/UserDetails'
import { NotificationsTab } from '../Enums'
import { InfoTooltip } from '../tooltips/InfoTooltip'
import { CapitalizedText } from '../TextComponents'
import { CostAnomalyLimitsTab } from '../../user-profile/notifications/UserNotificationSettings'
import { IntegrationsTab } from '../../admin/integrations/Shared'
import { SustainabilityTab } from '../../sustainability/SustainabilityTabs'
import { MousePosition } from '../../../hooks/useMousePosition'

type TabType = Vendor | UserEditTab | NotificationsTab | CostAnomalyLimitsTab | IntegrationsTab | SustainabilityTab

export enum TabLevel {
  PRIMARY,
  SECONDARY
}

interface TabProps {
  level?: TabLevel
  selectedTab: TabType
  id?: string
  tabValue: TabType
  tabLabel?: React.ReactNode
  handleSelection: (selected: TabType) => void
  styles?: string
  tooltipText?: string
  tooltipPosition?: MousePosition
  highlight?: boolean
}

export const Tab = ({
  level = TabLevel.PRIMARY,
  selectedTab,
  id,
  tabValue,
  tabLabel,
  handleSelection,
  styles,
  tooltipText,
  tooltipPosition,
  highlight = false
}: TabProps) => {
  const [selected, setSelected] = useState<boolean>(false)
  const [hover, setHover] = useState<boolean>(false)

  useEffect(() => {
    setSelected(selectedTab === tabValue)
  }, [selectedTab, tabValue])

  return (
    <>
      <TabOption
        level={level}
        className={styles ? styles : 'lg:max-w-40'}
        id={id ? `${id}-${tabValue}-tab-option` : `${tabValue}-tab-option`}
        selected={selected}
        onClick={() => handleSelection(tabValue)}
        onMouseOver={() => setHover(true)}
        onMouseOut={() => setHover(false)}
      >
        <CapitalizedText
          className={'mt-px mb-1 tracking-wide cursor-pointer lowercase'}
          capitalizeAll={tabValue === Vendor.AWS || tabValue === Vendor.GCP}
        >
          {tabValue === Vendor.ALL ? t('common.summary') : tabLabel ? tabLabel : tabValue}
          {highlight && <TabPing selected={selected} />}
        </CapitalizedText>
        <TabColor hover={hover} selected={selected} selectedOption={tabValue} level={level} />
      </TabOption>
      {tooltipText && hover && (
        <InfoTooltip x={tooltipPosition?.x} y={tooltipPosition?.y} styles={'max-w-85'}>
          {tooltipText}
        </InfoTooltip>
      )}
    </>
  )
}

interface TabOptionProps {
  selected: boolean
  level: TabLevel
}

const TabOption = styled.div<TabOptionProps>`
  ${tw`flex flex-col py-3.5 relative transition-all ease-in-out cursor-pointer justify-center items-center text-center tracking-wide text-80 w-full md:text-90 lg:text-100`}
  ${({ level }) => (level === TabLevel.PRIMARY ? tw`py-3.5 font-semibold` : tw`py-2`)}
  ${({ selected, level }) =>
    level === TabLevel.PRIMARY
      ? selected
        ? tw`text-gray-50`
        : tw`text-gray-300 hover:text-gray-100`
      : selected
        ? tw`text-primary-500`
        : tw`text-gray-100 hover:text-primary-100`}
`

interface TabColorProps {
  selectedOption: TabType
  selected: boolean
  hover: boolean
  level: TabLevel
}

const TabColor = styled.div<TabColorProps>`
  ${tw`-mb-px bg-transparent w-full absolute bottom-0`}
  ${({ level }) => (level === TabLevel.PRIMARY ? tw`h-1` : tw`h-1px`)}
  ${({ selectedOption, selected, hover }) =>
    selectedOption === Vendor.AWS
      ? selected
        ? tw`bg-brand-aws`
        : hover && tw`bg-brand-aws-dark`
      : selectedOption === Vendor.GCP
        ? selected
          ? tw`bg-brand-gcp`
          : hover && tw`bg-brand-gcp-dark`
        : selectedOption === Vendor.AZURE
          ? selected
            ? tw`bg-brand-azure`
            : hover && tw`bg-brand-azure-dark`
          : selected
            ? tw`bg-primary-500/90`
            : hover && tw`bg-primary-500/40`}
`

interface TabPingProps {
  selected: boolean
}

const TabPing = ({ selected }: TabPingProps) => {
  return (
    <div className={'inline-flex absolute h-3 w-3 ml-2.5'}>
      <div className={'animate-ping absolute rounded-full h-full w-full bg-primary-300/80'}></div>
      <div className={`rounded-full h-full w-full ${!selected ? 'bg-primary-500/60' : 'bg-primary-500/90'}`}></div>
    </div>
  )
}
