import styled from 'styled-components'
import tw from 'twin.macro'
import React, { useState } from 'react'
import { InfoTooltip } from './tooltips/InfoTooltip'

export enum IconType {
  VECTOR,
  FLAT
}

export interface CustomIconProps {
  id?: string
  iconType?: IconType
  path: string
  alt?: string
  styles?: string
  onClick?: (event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  onHover?: (hover: boolean) => void
  tooltipText?: string
  tooltipStyles?: string
}

export const CustomIcon = ({
  id,
  iconType = IconType.VECTOR,
  path,
  alt,
  styles,
  onClick,
  onHover = () => void 0,
  tooltipText,
  tooltipStyles
}: CustomIconProps) => {
  const [showTooltip, setShowTooltip] = useState(false)
  const Icon = () =>
    iconType === IconType.VECTOR ? (
      <VectorIcon
        id={id}
        onClick={onClick}
        onMouseMove={() => {
          onHover(true)
          tooltipText && setShowTooltip(true)
        }}
        onMouseLeave={() => {
          onHover(false)
          tooltipText && setShowTooltip(false)
        }}
        className={`${styles ?? 'h-6 w-6 bg-gray-200'} ${onClick && 'cursor-pointer hover:bg-gray-50'}`}
        path={path}
        title={alt}
      />
    ) : (
      <FlatIcon
        id={id}
        onClick={onClick}
        className={`${styles ?? 'h-6 w-6'} ${onClick && 'cursor-pointer'}`}
        path={path}
        title={alt}
      />
    )

  return tooltipText ? (
    <div className={'relative'}>
      <Icon />
      {tooltipText && showTooltip && (
        <InfoTooltip styles={`mt-2 ${tooltipStyles}`} mousePosition={true} sticky={true}>
          {tooltipText}
        </InfoTooltip>
      )}
    </div>
  ) : (
    <Icon />
  )
}

interface IconProps {
  onClick?: () => void
  path?: string
}

const VectorIcon = styled.div<IconProps>`
  mask: url(${props => props.path}) no-repeat center;
  mask-size: 100%;
  ${props => props.onClick && tw`hover:cursor-pointer`}
`

const FlatIcon = styled.div<IconProps>`
  background: url(${props => props.path}) no-repeat center;
  background-size: contain;
  ${props => props.onClick && tw`hover:cursor-pointer`}
`
