import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, NavLink, useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import homeIcon from '../../assets/svg/sidebar/home-icon.svg'
import costsIcon from '../../assets/svg/sidebar/costs-icon.svg'
import complianceIcon from '../../assets/svg/sidebar/compliance-icon.svg'
import ticketsIcon from '../../assets/svg/sidebar/tickets-icon.svg'
import sustainabilityIcon from '../../assets/svg/sidebar/sustainability-icon.svg'
import systemAdminIcon from '../../assets/svg/sidebar/system-admin-icon.svg'
import infraIcon from '../../assets/svg/sidebar/infra-icon.svg'
import adminIcon from '../../assets/svg/sidebar/admin-icon.svg'
import optimizationIcon from '../../assets/svg/sidebar/optimization-icon.svg'
import notificationsIcon from '../../assets/svg/sidebar/notifications-icon.svg'
import userSettingsIcon from '../../assets/svg/sidebar/user-settings-icon.svg'
import masterAdminIcon from '../../assets/svg/sidebar/master-admin-icon.svg'
import spotterLogo from '../../assets/svg/brand/spotter-logo-glow.svg'
import { featuresFlags } from '../../state/featuresFlags'
import tw from 'twin.macro'
import { AuthInfoContext, AuthInfoState } from '../../state/context/AuthInfoContext'
import { UserRole } from '../../api/auth'
import { BackgroundOverlay } from '../../components/shared/layout/BackgroundOverlay'
import { CustomIcon, IconType } from '../../components/shared/CustomIcon'
import { ADMIN_ROLES } from '../../components/admin/roleConstants'
import { TenantMenu } from './TenantMenu'
import { CapitalizedText } from '../../components/shared/TextComponents'
import { Notifications } from '../../components/notifications/Notifications'
import { toast } from 'react-toastify'
import { NotificationsContext, NotificationsDrawerContext } from '../../state/context/NotificationsContext'
import { NotificationState } from '../../api/notifications'
import { InfoTooltip } from '../../components/shared/tooltips/InfoTooltip'
import { useMousePosition } from '../../hooks/useMousePosition'
import { useScreenSize } from '../../hooks/useScreenSize'
import { twLg } from '../../design/constants'
import { ModalContext } from '../../state/context/ModalContext'
import { firstLogin } from '../../state/storage'

export enum SidebarLinkId {
  HOME = 'qa-sidebar-home',
  COSTS = 'qa-sidebar-costs',
  OPTIMIZATION = 'qa-sidebar-optimization',
  COMPLIANCE = 'qa-sidebar-compliance',
  TICKETS = 'qa-sidebar-tickets',
  INFRA = 'qa-sidebar-infra',
  SUSTAINABILITY = 'qa-sidebar-sustainability',
  NOTIFICATIONS = 'qa-sidebar-notifications',
  ADMIN = 'qa-sidebar-admin',
  SYSTEM_ADMIN = 'qa-sidebar-sysadmin',
  MASTER_ADMIN = 'qa-sidebar-master-admin',
  PROFILE = 'qa-sidebar-profile',
  LOGOUT = 'qa-sidebar-logout'
}

interface SidebarItem {
  text: string
  path: string
  icon: string
  id: SidebarLinkId
  display: boolean
}

export const Sidebar = () => {
  const { t } = useTranslation()
  const { notificationsOpen, setNotificationsOpen } = useContext(NotificationsDrawerContext)
  const location = useLocation()
  const history = useHistory()
  const { setModal } = useContext(ModalContext)
  const { authInfo } = useContext<AuthInfoState>(AuthInfoContext)
  const isSysAdmin = authInfo.roles.includes(UserRole.SYSTEM_ADMIN)
  const hasIntegration =
    authInfo.awsIntegration.integration || authInfo.azureIntegration.integration || authInfo.gcpIntegration.integration
  const isAdmin = authInfo.roles.some(role => ADMIN_ROLES.includes(role))

  useEffect(() => {
    !location.pathname.includes('/integrations') &&
      !location.pathname.includes('/profile') &&
      !hasIntegration &&
      isAdmin &&
      history.push('/admin/integrations')

    if (location.pathname.includes('/profile') && !hasIntegration) setModal(null)
  }, [location, hasIntegration, isAdmin, history])

  const sidebarLinks: SidebarItem[] = [
    {
      text: t('sidebar.home'),
      path: '/home',
      icon: homeIcon,
      id: SidebarLinkId.HOME,
      display: featuresFlags.home
    },
    {
      text: t('sidebar.costs'),
      path: '/costs',
      icon: costsIcon,
      id: SidebarLinkId.COSTS,
      display: featuresFlags.costs && authInfo.costsAccess
    },
    {
      text: t('sidebar.optimization'),
      path: '/optimization',
      icon: optimizationIcon,
      id: SidebarLinkId.OPTIMIZATION,
      display: featuresFlags.optimization && authInfo.optimizationAccess
    },
    {
      text: t('sidebar.compliance'),
      path: '/compliance',
      icon: complianceIcon,
      id: SidebarLinkId.COMPLIANCE,
      display: featuresFlags.compliance && authInfo.complianceAccess
    },
    {
      text: t('sidebar.tickets'),
      path: '/tickets',
      icon: ticketsIcon,
      id: SidebarLinkId.TICKETS,
      display: featuresFlags.tickets && authInfo.freshIntegration !== undefined && authInfo.ticketAccess
    },
    {
      text: 'infra',
      path: '/infra',
      icon: infraIcon,
      id: SidebarLinkId.INFRA,
      display: featuresFlags.infra && authInfo.infraAccess
    },
    {
      text: t('sidebar.sustainability'),
      path: '/sustainability',
      icon: sustainabilityIcon,
      id: SidebarLinkId.SUSTAINABILITY,
      display: featuresFlags.sustainability && authInfo.sustainabilityAccess
    },
    {
      text: t('sidebar.notifications'),
      path: location.pathname,
      icon: notificationsIcon,
      id: SidebarLinkId.NOTIFICATIONS,
      display: featuresFlags.notifications && authInfo.notificationsAccess
    }
  ]

  authInfo.roles.some(role => ADMIN_ROLES.includes(role)) &&
    sidebarLinks.push({
      text: t('sidebar.admin'),
      path: '/admin',
      icon: adminIcon,
      id: SidebarLinkId.ADMIN,
      display: featuresFlags.admin
    })

  authInfo.roles.some(role => role === UserRole.SYSTEM_ADMIN || role === UserRole.MASTER_ADMIN) &&
    sidebarLinks.push({
      text: t('sidebar.systemAdmin'),
      path: '/system-admin',
      icon: systemAdminIcon,
      id: SidebarLinkId.SYSTEM_ADMIN,
      display: featuresFlags.systemAdmin
    })

  authInfo.roles.includes(UserRole.MASTER_ADMIN) &&
    sidebarLinks.push({
      text: t('sidebar.masterAdmin'),
      path: '/master-admin',
      icon: masterAdminIcon,
      id: SidebarLinkId.MASTER_ADMIN,
      display: featuresFlags.masterAdmin
    })

  sidebarLinks.push({
    text: t('sidebar.userSettings.profile'),
    path: '/profile',
    icon: userSettingsIcon,
    id: SidebarLinkId.PROFILE,
    display: true
  })

  return (
    <>
      <SideBarContainer>
        <Container className={'no-scrollbar'}>
          <div>
            {isSysAdmin && (
              <div className={'border-b border-gray-500'}>
                <TenantMenu />
              </div>
            )}
            <LogoContainer>
              <Link to={'/home'}>
                <CustomIcon
                  id={'qa-sidebar-logo'}
                  iconType={IconType.VECTOR}
                  path={spotterLogo}
                  styles={'bg-primary-500 w-[70%] h-12 md:h-20 xl:w-[75%] xl:h-28'}
                />
              </Link>
              {!isSysAdmin && <div className={'text-center text-gray-50'}>{authInfo.tenantName}</div>}
            </LogoContainer>
            <LinksContainer>
              {sidebarLinks.map(link =>
                link.display ? (
                  link.id === SidebarLinkId.NOTIFICATIONS ? (
                    <NotificationLink key={link.text} link={link} />
                  ) : (
                    <SideBarLink key={link.text} link={link} />
                  )
                ) : null
              )}
            </LinksContainer>
          </div>
        </Container>
        {featuresFlags.notifications && authInfo.notificationsAccess && <Notifications />}
      </SideBarContainer>

      <BackgroundOverlay visible={notificationsOpen} clickHandler={() => setNotificationsOpen(false)} />
    </>
  )
}

const SideBarContainer = styled.div`
  ${tw`flex h-screen sticky top-0 z-1000`}
`

const Container = styled.div`
  ${tw`flex flex-col border-r border-gray-500 z-1000 justify-center justify-between overflow-y-auto transition-all ease-in-out w-16 lg:w-29 2xl:w-50`}
`

const LinksContainer = styled.div`
  ${tw`flex flex-col justify-center`}
  #qa-sidebar-admin {
    ${tw`sm:mt-6`}
  }
`

const LogoContainer = styled.div`
  ${tw`flex flex-col gap-2 my-9`}
  a {
    ${tw`flex flex-col items-center`}
  }
`

interface SideBarLinkProps {
  link: SidebarItem
}

const SideBarLink = ({ link }: SideBarLinkProps) => {
  const { notificationsOpen, setNotificationsOpen } = useContext(NotificationsDrawerContext)
  return (
    <TooltipContainer link={link}>
      <LinkContainer
        onFocus={() => setNotificationsOpen(false)}
        key={link.text}
        to={link.path}
        activeClassName={notificationsOpen ? 'selected-link-dimmed' : 'selected-link'}
        id={link.id}
      >
        <CustomIcon
          id={'sidebar-link-icon'}
          iconType={IconType.VECTOR}
          path={link.icon}
          styles={'bg-gray-300 w-6 h-6'}
        />
        <CapitalizedText className={'text-75 min-w-max hidden lg:block 2xl:text-90'}>{link.text}</CapitalizedText>
      </LinkContainer>
    </TooltipContainer>
  )
}

const LinkContainer = styled(NavLink)`
  ${tw`flex flex-col px-3 py-4 justify-center items-center border-r-4 border-transparent text-gray-300 transition-all ease-in-out duration-200 gap-0 lg:gap-2 2xl:px-5 2xl:gap-3 2xl:flex-row 2xl:justify-start`}
  &.selected-link, &.selected-link-dimmed {
    ${tw`text-gray-50 ml-1 border-primary-500`}
    #sidebar-link-icon {
      ${tw`bg-gray-50`}
    }
  }

  &:hover:not(.selected-link):not(.selected-link-dimmed) {
    ${tw`text-gray-50/70 ml-1 border-primary-500/60`}
    #sidebar-link-icon {
      ${tw`bg-gray-50/70`}
    }
  }
`

const NotificationLink = ({ link }: SideBarLinkProps) => {
  const { notificationsOpen, setNotificationsOpen } = useContext(NotificationsDrawerContext)
  const { notifications } = useContext(NotificationsContext)
  const unreadNotifications = notifications?.filter(
    n => n.notificationState === NotificationState.UNREAD || n.notificationState === NotificationState.NEW
  ).length

  return (
    <TooltipContainer link={link}>
      <NotificationsLinkContainer
        className={'group'}
        active={notificationsOpen}
        id={link.id}
        onClick={() => {
          !firstLogin() && setNotificationsOpen(!notificationsOpen)
          toast.dismiss()
        }}
      >
        <CustomIcon
          iconType={IconType.VECTOR}
          path={link.icon}
          styles={`${notificationsOpen ? 'bg-gray-50 group-hover:bg-gray-50' : 'bg-gray-300 group-hover:bg-gray-50/70'} min-w-6 w-6 h-6`}
        />
        <div className={'flex items-center gap-1.5 flex-col md:flex-row'}>
          <CapitalizedText className={'text-75 min-w-max hidden lg:block 2xl:text-90'}>{link.text}</CapitalizedText>
          {unreadNotifications > 0 && (
            <div
              className={
                'flex items-center justify-center tracking-tight leading-none rounded-full bg-primary-500 text-gray-50 font-semibold text-75 min-w-4 h-4 px-1.5 2xl:text-80'
              }
            >
              {unreadNotifications}
            </div>
          )}
        </div>
      </NotificationsLinkContainer>
    </TooltipContainer>
  )
}

interface NotificationsLinkContainerProps {
  active: boolean
}

const NotificationsLinkContainer = styled.div<NotificationsLinkContainerProps>`
  ${tw`flex flex-col-reverse px-3 py-4 justify-center border-r-4 border-transparent items-center text-gray-300 transition-all ease-in-out duration-200 gap-1 cursor-pointer hover:ml-1 lg:gap-2 lg:flex-col 2xl:px-5 2xl:gap-3 2xl:flex-row 2xl:justify-start`}
  ${({ active }) =>
    active
      ? tw`ml-1 text-gray-50 border-primary-500 hover:border-primary-500`
      : tw`hover:text-gray-50/70 hover:border-primary-500/60`}
`

interface TooltipContainerProps {
  children: React.ReactNode
  link: SidebarItem
}

const TooltipContainer = ({ children, link }: TooltipContainerProps) => {
  const [tooltip, showTooltip] = useState(false)
  const mousePos = useMousePosition()
  const screenSize = useScreenSize()

  return (
    <div onMouseOver={() => showTooltip(true)} onMouseLeave={() => showTooltip(false)}>
      {children}
      {screenSize.width < twLg && tooltip && (
        <InfoTooltip x={mousePos.x + 12} styles={'-mt-10'}>
          {link.text}
        </InfoTooltip>
      )}
    </div>
  )
}
