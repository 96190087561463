import React, { useContext, useEffect, useState } from 'react'
import { UserRole } from '../../../api/auth'
import { Vendor } from '../../../utils/vendors'
import { AuthInfoContext, AuthInfoState } from '../../../state/context/AuthInfoContext'
import { useTranslation } from 'react-i18next'
import { CustomIcon, IconType } from '../../shared/CustomIcon'
import adminIcon from '../../../assets/svg/brand/plain-logo.svg'
import { CapitalizedText, LightGrayText, WhiteText } from '../../shared/TextComponents'
import { AWS_ROLES, AZURE_ROLES, GCP_ROLES, VENDOR_USER_ROLES } from '../../admin/roleConstants'
import awsIcon from '../../../assets/svg/vendors/aws.svg'
import azureIcon from '../../../assets/svg/vendors/azure.svg'
import gcpIcon from '../../../assets/svg/vendors/gcp.svg'
import { ScrollContainer } from '../../shared/containers/ScrollContainer'
import styled from 'styled-components'
import tw from 'twin.macro'
import check from '../../../assets/svg/symbols/check-heavy.svg'
import cross from '../../../assets/svg/actions/close-heavy.svg'
import { PermittedVendorProject } from '../../../api/admin/users'
import { useCancelToken } from '../../../api/client'
import { getUserPermittedProjects } from '../../../api/user/profile'
import { useErrorHandling } from '../../../hooks/handleError'

export const UserPermissions = () => {
  const { t } = useTranslation()
  const { authInfo } = useContext<AuthInfoState>(AuthInfoContext)

  return (
    <>
      <div className={'flex flex-col gap-3'}>
        <WhiteText className={'first-letter:capitalize font-semibold text-90'}>
          {t('userSettings.accessRights')}
        </WhiteText>
        {authInfo.roles.find(role => role === UserRole.ADMIN) ? (
          <UserAdminAccess />
        ) : (
          <div className={'flex flex-col gap-3'}>
            {authInfo.awsIntegration.integration && <UserVendorAccess vendor={Vendor.AWS} />}
            {authInfo.azureIntegration.integration && <UserVendorAccess vendor={Vendor.AZURE} />}
            {authInfo.gcpIntegration.integration && <UserVendorAccess vendor={Vendor.GCP} />}
          </div>
        )}
      </div>
      <div className={'flex flex-col gap-3'}>
        <WhiteText className={'first-letter:capitalize font-semibold text-90'}>
          {t('userSettings.spotterVisibility')}
        </WhiteText>
        <UserVisibilityInfo />
      </div>
    </>
  )
}

const UserAdminAccess = () => {
  const { t } = useTranslation()
  return (
    <IconTextWrapper>
      <CustomIcon styles={`${iconSize} bg-gray-50`} iconType={IconType.VECTOR} path={adminIcon} />
      <LightGrayText>
        {t('userSettings.accessRoleIntro') + ' '}
        <Role>{t(`admin.roles.${UserRole.ADMIN}`)}</Role>{' '}
        {t(`userSettings.accessToAllProjects`, { projects: t('userSettings.organizationData') })}
      </LightGrayText>
    </IconTextWrapper>
  )
}

const iconSize = 'w-6 h-6'

interface UserVendorAccessProps {
  vendor: Vendor
}

const UserVendorAccess = ({ vendor }: UserVendorAccessProps) => {
  const { t } = useTranslation()
  const { authInfo } = useContext<AuthInfoState>(AuthInfoContext)
  const { createCancelToken } = useCancelToken()
  const handleError = useErrorHandling()
  const [permittedProjects, setPermittedProjects] = useState<PermittedVendorProject[]>([])

  useEffect(() => {
    authInfo.roles.some(role => VENDOR_USER_ROLES.includes(role)) &&
      getUserPermittedProjects(vendor, createCancelToken().token).then(setPermittedProjects).catch(handleError)
  }, [vendor, authInfo, createCancelToken, handleError])

  const role = authInfo.roles.find(role =>
    vendor === Vendor.AWS
      ? AWS_ROLES.includes(role)
      : vendor === Vendor.AZURE
        ? AZURE_ROLES.includes(role)
        : GCP_ROLES.includes(role)
  )

  if (authInfo.roles.some(role => VENDOR_USER_ROLES.includes(role))) {
  }
  const showPermittedProjects = permittedProjects.length && role && VENDOR_USER_ROLES.includes(role)

  const iconPath = vendor === Vendor.AWS ? awsIcon : vendor === Vendor.AZURE ? azureIcon : gcpIcon

  return (
    <>
      {role ? (
        <InfoContainer className={'py-3'}>
          <IconTextWrapper>
            <CustomIcon styles={`${iconSize} bg-gray-50`} iconType={IconType.VECTOR} path={iconPath} />
            <LightGrayText>
              {t('userSettings.accessRoleIntro') + ' '}
              <Role>{t(`admin.roles.${role}`)}</Role>{' '}
              {showPermittedProjects
                ? t(`userSettings.permittedProjectsHeading`, {
                    projects: t(`vendors.${vendor}.projectPhrase_other`),
                    count: permittedProjects.length
                  })
                : t(`userSettings.accessToAllProjects`, { projects: t(`vendors.${vendor}.projectPhrase_other`) })}
            </LightGrayText>
          </IconTextWrapper>
          {showPermittedProjects ? (
            <ScrollContainer className={'max-h-72 max-w-124 divide-y divide-dotted divide-gray-500 divide-opacity-50'}>
              {permittedProjects.map(project => (
                <div key={project.value} className={'text-gray-50 px-4 py-2 text-90 break-all'}>
                  {project.label}
                </div>
              ))}
            </ScrollContainer>
          ) : null}
        </InfoContainer>
      ) : (
        <IconTextWrapper className={'py-3'}>
          <CustomIcon styles={`${iconSize} bg-gray-200`} iconType={IconType.VECTOR} path={iconPath} />
          <LightGrayText>
            {t('userSettings.noAccessToVendorData', {
              vendor: t(`vendors.${vendor}.long`)
            })}
          </LightGrayText>
        </IconTextWrapper>
      )}
    </>
  )
}

const Role = styled.span`
  ${tw`capitalize text-90 text-gray-50 font-semibold`}
`

const UserVisibilityInfo = () => {
  const { t } = useTranslation()
  const { authInfo } = useContext<AuthInfoState>(AuthInfoContext)
  const userVisibility = [
    authInfo.costsAccess,
    authInfo.optimizationAccess,
    authInfo.complianceAccess,
    authInfo.ticketAccess,
    authInfo.infraAccess,
    authInfo.sustainabilityAccess
  ].filter(Boolean)

  const visibilityOptions = [
    {
      label: t('sidebar.costs'),
      hasVisibility: authInfo.costsAccess
    },
    {
      label: t('sidebar.optimization'),
      hasVisibility: authInfo.optimizationAccess
    },
    {
      label: t('sidebar.compliance'),
      hasVisibility: authInfo.complianceAccess
    }
  ]

  authInfo.freshIntegration &&
    visibilityOptions.push({
      label: t('sidebar.tickets'),
      hasVisibility: authInfo.ticketAccess
    })

  visibilityOptions.push(
    {
      label: t('sidebar.infra'),
      hasVisibility: authInfo.infraAccess
    },
    {
      label: t('sidebar.sustainability'),
      hasVisibility: authInfo.sustainabilityAccess
    }
  )

  return (
    <InfoContainer>
      {userVisibility.filter(Boolean).length === 0 ? (
        <LightGrayText>{t('userSettings.noFeatureRights')}</LightGrayText>
      ) : (
        <LightGrayText>
          {userVisibility.filter(Boolean).length !== visibilityOptions.length
            ? t('userSettings.accessToFeatures.restricted')
            : t('userSettings.accessToFeatures.all')}
        </LightGrayText>
      )}
      <InfoContainer>
        {visibilityOptions.map(props => (
          <IconTextWrapper key={props.label}>
            <CustomIcon
              styles={`w-4 h-4 ${props.hasVisibility ? 'bg-gray-50' : 'bg-gray-200'}`}
              iconType={IconType.VECTOR}
              path={props.hasVisibility ? check : cross}
            />
            <CapitalizedText className={props.hasVisibility ? 'text-gray-50' : 'text-gray-200'}>
              {props.label}
            </CapitalizedText>
          </IconTextWrapper>
        ))}
      </InfoContainer>
    </InfoContainer>
  )
}

const InfoContainer = styled.div`
  ${tw`flex flex-col gap-4`}
`

const IconTextWrapper = styled.div`
  ${tw`flex gap-2 items-center`}
`
