import React, { useContext, useEffect, useState } from 'react'
import { AuthInfoContext, AuthInfoState } from '../../state/context/AuthInfoContext'
import { useCancelToken } from '../../api/client'
import { useErrorHandling } from '../../hooks/handleError'
import { FilterOption } from '../../components/shared/filters/FilterSelect'
import { changeCurrentTenant, getSystemAdminTenantOptions } from '../../api/system-admin'
import { getAuthInfo } from '../../api/auth'
import Select, { SingleValue } from 'react-select'
import {
  SelectDropdownIndicator,
  selectMenuListStyles,
  selectOptionStyles
} from '../../components/shared/filters/ReactSelectStyles'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getCostAnomalyNotifications } from '../../api/notifications'
import { NotificationsContext } from '../../state/context/NotificationsContext'

export const TenantMenu = () => {
  const { authInfo, setAuthInfo } = useContext<AuthInfoState>(AuthInfoContext)
  const { setNotifications } = useContext(NotificationsContext)
  const { createCancelToken } = useCancelToken()
  const handleError = useErrorHandling()
  const pathParams = useParams()
  const history = useHistory()
  const [tenants, setTenants] = useState<FilterOption[]>([])
  const [currentTenant, setCurrentTenant] = useState<FilterOption>({
    value: authInfo.tenantId,
    label: authInfo.tenantName
  })
  const currentLocation = history.location.pathname

  useEffect(() => {
    const cancelToken = createCancelToken()
    getSystemAdminTenantOptions(cancelToken.token)
      .then(resp =>
        setTenants(
          resp.map(tenant => ({
            value: tenant.id,
            label: tenant.name
          }))
        )
      )
      .catch(handleError)

    return () => {
      cancelToken.cancel()
      setTenants([])
    }
  }, [createCancelToken, handleError])

  const handleTenantChange = (tenant: FilterOption) => {
    const cancelToken = createCancelToken()
    changeCurrentTenant(cancelToken.token, tenant.value)
      .then(() => {
        getAuthInfo(cancelToken.token).then(resp => {
          setAuthInfo(resp)
          setCurrentTenant({ value: resp.tenantId, label: resp.tenantName })
          history.push('/*')
          setTimeout(() => {
            if (JSON.stringify(pathParams) !== JSON.stringify({})) {
              const paramNeutralPath = currentLocation.split('/').slice(0, -1).join('/')
              history.push(paramNeutralPath)
            } else history.push(currentLocation)
          }, 0)
          toast.dismiss()
          getCostAnomalyNotifications(cancelToken.token).then(setNotifications).catch(handleError)
        })
      })
      .catch(e => {
        handleError(e)
        setCurrentTenant({ value: authInfo.tenantId, label: authInfo.tenantName })
      })
  }

  return (
    <div className={'px-2 pt-2.5 lg:py-2.5'}>
      <Select
        unstyled
        styles={{ menuList: base => ({}) }}
        classNames={{
          control: () => 'flex items-center hover:cursor-pointer flex-col text-75 lg:text-100 lg:flex-row',
          menuList: () => selectMenuListStyles + ` fixed min-w-60 max-h-75 py-1.5 overflow-y-auto`,
          option: state =>
            selectOptionStyles + `${(state.isSelected || state.isFocused) && ' bg-gray-600 text-gray-50'}`,
          indicatorsContainer: () => 'flex items-center justify-center lg:justify-start'
        }}
        defaultValue={currentTenant}
        value={currentTenant}
        options={tenants}
        onChange={(selected: SingleValue<FilterOption>) => handleTenantChange(selected as FilterOption)}
        components={{
          DropdownIndicator: SelectDropdownIndicator
        }}
      />
    </div>
  )
}
