import React from 'react'

import { ModalContextProvider } from './context/ModalContext'
import { AuthInfoContextProvider } from './context/AuthInfoContext'
import { MessageContextProvider } from './context/MessageContext'
import { NotificationsContextProvider } from './context/NotificationsContext'
import { UserSettingsContextProvider } from './context/UserSettingsContext'
import { FiltersContextProvider } from './context/FiltersContext'

const StateProvider = React.memo(({ children }: { children: JSX.Element }) => {
  return (
    <MessageContextProvider>
      <AuthInfoContextProvider>
        <UserSettingsContextProvider>
          <NotificationsContextProvider>
            <ModalContextProvider>
              <FiltersContextProvider>{children}</FiltersContextProvider>
            </ModalContextProvider>
          </NotificationsContextProvider>
        </UserSettingsContextProvider>
      </AuthInfoContextProvider>
    </MessageContextProvider>
  )
})

export default StateProvider
