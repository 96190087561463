import { createContext, useState } from 'react'

export interface FiltersState {
  isFiltered: boolean
  setIsFiltered: (isFiltered: boolean) => void
  filtersOpen: boolean
  setFiltersOpen: (isOpen: boolean) => void
}

const defaultFiltersState: FiltersState = {
  isFiltered: false,
  setIsFiltered: () => undefined,
  filtersOpen: false,
  setFiltersOpen: () => undefined
}

export const FiltersContext = createContext<FiltersState>(defaultFiltersState)

interface Props {
  children: JSX.Element
}

export const FiltersContextProvider = ({ children }: Props) => {
  const [filtersOpen, setFiltersOpen] = useState(defaultFiltersState.filtersOpen)
  const [isFiltered, setIsFiltered] = useState(defaultFiltersState.isFiltered)
  const value = {
    isFiltered,
    setIsFiltered,
    filtersOpen,
    setFiltersOpen
  }

  return <FiltersContext.Provider value={value}>{children}</FiltersContext.Provider>
}
